



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {BlockchainDailyCache} from '@/model/resource/BlockchainDailyCache'
import {InputBlockchainDailyCacheSchema} from '@/schema/resource/BlockchainDailyCache/InputBlockchainDailyCacheSchema'

@Component
export default class PersistBlockchainDailyCacheView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputBlockchainDailyCacheSchema()
  blockchainDailyCache = new BlockchainDailyCache()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.blockchainDailyCache.getBlockchainDailyCache(id)
    }

    this.$await.done('getBlockchainDailyCache')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.blockchainDailyCache.persistBlockchainDailyCache()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/blockchain-daily-cache/list')
  }
}
